<template>
  <div class="login">
    <form class="form-group" action="javascript:void(0);">
      <div class="input-group">
        <label for="key" class="input-group-text">Key</label>
        <input
          v-model="userkey"
          class="form-control"
          :type="userkeyType"
          placeholder="Enter your admin key"
          required
          style="padding-right: 42px"
        />
        <div v-if="userkey != ''">
          <button
            type="button"
            class="btn btn-secondary- bg-transparent"
            @click="changeUserkeyType()"
            style="margin-left: -48px; z-index: 100"
          >
            &#128065;
          </button>
        </div>
        <div v-if="userkey != '' && checkKey()">
          <button type="button" class="btn btn-primary" @click="doSave">
            Save
          </button>
        </div>
        <div v-if="userkey != '' && !checkKey()">
          <button type="button" class="btn btn-primary" @click="doClear">
            Clear
          </button>
        </div>
      </div>
    </form>
    <br />
    <edit-config
      name="Edit login config"
      jsonName="Login config"
      subject="Login config."
      :config="'users/' + userkey"
    />
  </div>
</template>

<script>
import EditConfig from "./EditConfig.vue";

const cmdUsersSet = "users.set/";
const cmdAccessRead = "users.access-read";
const cmdAccessWrite = "users.access-write";

export default {
  name: "TeoLogin",
  components: {
    EditConfig,
  },
  data() {
    return {
      userkey: "",
      userkeyType: "password",
    };
  },
  mounted: function () {
    this.userkey = localStorage.userkey;
    if (this.userkey != "") {
      // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
      this.teoweb.whenConnected(() => {
        this.teoweb.sendCmd(cmdUsersSet + this.userkey);
        // send after 500 ms
        setTimeout(() => {
          this.teoweb.sendCmd(cmdAccessWrite);
          this.teoweb.sendCmd(cmdAccessRead);
        }, 500);
      });
    }
  },
  methods: {
    /**
     * Saves the user key to local storage and logs it.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    doSave() {
      // Save user key
      let u = this.userkey;
      localStorage.userkey = u;

      // Update user key to switch buttons in form
      this.userkey = "";
      this.userkey = u;

      // Set user
      this.teoweb.sendCmd(cmdUsersSet + this.userkey);
      this.teoweb.sendCmd(cmdAccessRead);
      this.teoweb.sendCmd(cmdAccessWrite);
    },

    /**
     * Clears the user key from local storage and sets the component's userkey
     * to an empty string.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    doClear() {
      localStorage.userkey = "";
      this.userkey = "";

      // Set user
      this.teoweb.sendCmd(cmdUsersSet + this.userkey);
    },

    /**
     * A description of the entire function.
     *
     * @return {Boolean} Indicates whether the userkey is different from the
     * one stored in localStorage.
     */
    checkKey() {
      return this.userkey != localStorage.userkey;
    },

    /**
     * Toggles the type of the userkey input field between "password" and "text".
     *
     * @return {void}
     */
    changeUserkeyType() {
      this.userkeyType = this.userkeyType == "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
input.btn.btn-primary {
  margin-top: 10px;
}
</style>
