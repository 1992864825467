<!-- Teo connection info -->
<template>
  <!-- Connect info -->
  <div class="connect row">
    <!-- Left column -->
    <div class="col">
      server: <span id="name">{{ info.name }}</span
      ><br />
      online:
      <span id="online" :class="info.online ? 'online' : 'offline'">{{
        info.online
      }}</span
      ><br />
      uptime: <span id="uptime">{{ info.uptime }}</span
      ><br />
      version: <span id="version">{{ info.version }}</span
      ><br />
      clients: <span id="clients">{{ info.clients }}</span
      ><br />
      <a href="./" class="btn btn-sm" :class="[!admin ? 'btn-secondary' : 'btn-outline-secondary' ]">Game</a>
    </div>
    <!-- Right column -->
    <div class="col">
      <span>{{ shortname() }}</span
      ><br />
      user: <span>{{ user.username }}</span
      ><br />
      id: <span>{{ shortid() }}</span
      ><br />
      <br />
      <br />
      <a href="#/adminconnect" class="btn btn-sm" :class="[admin ? 'btn-secondary' : 'btn-outline-secondary' ]">Admin</a>
    </div>
  </div>
</template>

<script>
// Defalut teoweb commands
const cmdClients = "clients";

// This application teoweb commands
const cmdName = "name";
const cmdUptime = "uptime";
const cmdVersion = "version";

export default {
  name: "ConnectInfo",
  data() {
    return {
      info: {
        name: "",
        version: "",
        uptime: "",
        clients: 0,
        online: false,
      },

      // teoname: "",
      user: {
        id: "",
        username: "",
      },
    };
  },

  props: {
    doconnect: Boolean,
    showinfo: Boolean,
    admin: Boolean,
  },

  mounted: function () {
    if (localStorage.info) {
      this.info = JSON.parse(localStorage.info);
    }

    window.vuethis = this;
    this.user = this.tguser();
    if (this.doconnect) {
      this.teoweb.close();
      // Wait 1000ms to previouse connection stopped and try to connect.
      setTimeout(() => {
        this.connect();
      }, "1000");
    } else if (this.showinfo) {
      this.addReader();
      this.onOpenClose();
      this.sendRequests();
    }
  },

  unmounted: function () {
    localStorage.info = JSON.stringify(this.info);
    // clearInterval(this.intervalId);
    if (this.reader) {
      this.teoweb.delReader(this.reader);
    }
  },

  methods: {
    setIdText(id, value) {
      console.debug("setIdText", id, value);
      if (id == "online") {
        this.info.online = value;
      }
    },

    tguser() {
      // Get user from telegram
      let tg = window.Telegram;
      if (!(
        tg == undefined ||
        tg.WebApp == undefined ||
        tg.WebApp.initDataUnsafe == undefined ||
        tg.WebApp.initDataUnsafe.user == undefined
      )) {
        // Get user from telegram
        return tg.WebApp.initDataUnsafe.user;
      }

      // Get user from local storage
      if (localStorage.getItem("tguser")) {
        return JSON.parse(localStorage.getItem("tguser"));
      }

      // Generate uuid and save to local storage
      let uuid = window.uuidv4();
      let tguser = { id: uuid, username: "" }; 
      localStorage.setItem("tguser", JSON.stringify(tguser));
      return tguser;
    },

    shortname() {
      const l = 20;
      if (this.$gl.teoname.length > l) {
        return this.$gl.teoname.substring(0, l) + "...";
      }
      return this.$gl.teoname;
    },

    shortid() {
      const l = 20;
      if (this.user.id.length > l) {
        return this.user.id.substring(0, l) + "...";
      }
      return this.user.id;
    },

    /** Connect to Teonet server. */
    connect() {
      // Get this browser name from local storage
      this.$gl.teoname = this.uuidv4();
      console.debug("teoname:", this.$gl.teoname);

      // Connect to Teonet proxy WebRTC server
      const serverName = "asuzs2"; // TODO: Get WenRTC server name from some parameter
      const port = "8083"; // TODO: Get ws server port from some parameter
      let scheme;
      let host;
      const hostname = "signal.teonet.dev";
      if (hostname == "localhost" || hostname == "127.0.0.1") {
        scheme = "ws";
        host = hostname + ":" + port;
      } else {
        scheme = "wss";
        host = hostname;
      }
      this.teoweb.connect(
        scheme + "://" + host + "/signal",
        this.$gl.teoname,
        serverName
      );

      // Listen for WebRTC on open and on close event
      this.onOpenClose();

      // Listen WebRTC data
      this.addReader();
    },

    addReader() {
      let that = this;

      // Listen for WebRTC data from server
      this.reader = this.teoweb.addReader(function (gw, data) {
        switch (gw.command) {
          case cmdClients:
            that.info.clients = data;
            break;
          case cmdName:
            that.info.name = data;
            break;
          case cmdVersion:
            that.info.version = data;
            break;
          case cmdUptime:
            that.info.uptime = data;
            break;
        }
      });
    },

    sendRequests() {
      this.teoweb.sendCmd(cmdName);
      this.teoweb.sendCmd(cmdUptime);
      this.teoweb.sendCmd(cmdVersion);
      this.teoweb.sendCmd(cmdClients);
      this.teoweb.subscribeCmd(cmdClients);
    },

    onOpenClose() {
      let that = this;

      if (this.teoweb.connected()) {
        this.info.online = true;
      } else {
        this.info.online = false;
      }

      // Listen for WebRTC on open event
      this.teoweb.onOpen(function () {
        console.debug("teoConnect onOpen");
        that.info.online = true;
        that.disconnected = false;
        that.sendRequests();
      });

      // Listen for WebRTC on close event
      this.teoweb.onClose(function (b) {
        console.debug("teoConnect onClose, b =", b);
        that.info.online = false;

        // When b is undefined it means that the connection to webrtc server was
        // lost (the server was disconnected). In this case teoweb will be
        // disconnected himself.
        //
        // When b is true it means that the dc connection was closed. In this
        // case we need reconnect by calling connect() func.
        if (b === true) {
          that.disconnected = true;
          console.debug("teoConnect reconnect");
          that.connect();
        }
      });
    },
  },
};
</script>

<style scoped>
.connect {
  font-size: small;
}

.connect .online {
  color: green;
}

.connect .offline {
  color: red;
}
</style>
