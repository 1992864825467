<template>
  <div v-if="canRead" class="">
    <button
      class="btn btn-sm dropdown-toggle"
      :class="
        width + ' ' + (outline ? 'btn-outline-secondary' : 'btn-secondary')
      "
      type="button"
      @click="doShow"
    >
      {{ name }}
    </button>

    <div v-if="subject" class="subject" :class="width">
      {{ subject }}
    </div>
    
    <div v-if="showForm">
      <EditJson :edit="edit" @save="doSave" @cancel="doShow" />
    </div>
  </div>
</template>

<script>
import EditJson from "./EditJson.vue";

const cmdGetConfig = "config.get/";
const cmdSetConfig = "config.set/";
const cmdAccessRead = "users.access-read";
const cmdAccessWrite = "users.access-write";

export default {
  name: "EditConfig",
  components: {
    EditJson,
  },
  props: {
    name: String,
    jsonName: String,
    subject: String,
    config: String,
    outline: Boolean,
    width: String,
  },
  data() {
    return {
      showForm: false,
      canRead: false,
      edit: {
        name: this.jsonName,
        json: { someField: "someValue" },
      },
    };
  },

  mounted: function () {
    let that = this;

    // TODO: remove this when cmdAccessWrite will be implemented
    // this.edit.canRead = true;
    this.edit.cmdSave = cmdSetConfig + this.config;

    this.reader = this.teoweb.addReader(function (gw, data) {
      // console.debug(
      //   "execute reader in 'UpdateAMI.vue' with parameters, gw:",
      //   gw,
      //   "data:",
      //   data
      // );
      switch (gw.command) {
        case cmdAccessRead:
          if (!gw.err) {
            that.canRead = true;
          }
          break;

        case cmdAccessWrite:
          if (!gw.err) {
            that.canRead = true;
            that.edit.canSave = true;
          }
          break;

        case cmdGetConfig + that.config: {
          let jsonStr = "" + data;
          console.debug("cmdGetConfig answer received", jsonStr);
          if (gw.err) {
            that.error = "Error: " + gw.err;
            break;
          }

          that.edit.json = JSON.parse(jsonStr);
          that.showForm = true;
          break;
        }
      }
    });

    // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdAccessRead);
      this.teoweb.sendCmd(cmdAccessWrite);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** do when show or canceled */
    doShow() {
      if (!this.showForm) {
        this.teoweb.sendCmd(cmdGetConfig + this.config);
        return;
      }
      this.showForm = false;
    },

    /** do when saved */
    doSave() {
      this.showForm = false;
    },
  },
};
</script>

<style scoped>
.subject {
  color: gray;
  font-size: small;
  margin-bottom: 15px;
}
.config-edit {
  margin-bottom: 25px;
}
</style>
