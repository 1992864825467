<template>
  <div>
    <!-- {{ id }} -->
    <div v-if="host" class="host">
      <h3>{{ index }}. {{ host.ip + ":" + host.port }}</h3>
      <div class="subtitle">
        <div v-if="host.online >= 0" class="online">
          online: {{ host.online }}
        </div>
        <div v-if="host.online < 0" class="offline">offline</div>
      </div>
      <div>
        Id: {{ id }}<br />
        Maximum number of available servers: {{ host.num }}<br />
        <div v-if="host.online > 0">
          Number of running servers: {{ host.online }}<br />
        </div>
        Available games:<br />
        <span class="lines">{{ games(host.games) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const cmdHost = "host/";

export default {
  name: "TeoHost",
  props: {
    id: String,
    index: Number,
  },
  data() {
    return {
      host: null,
    };
  },

  mounted: function () {
    let that = this;
    let cmd = cmdHost + this.id;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmd:
          that.host = JSON.parse(data);
          break;
      }
    });

    // Send 'host/{id}' command to WebRTC server every 5 seccont after connected
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmd);
      that.intervalId = setInterval(function () {
        that.teoweb.sendCmd(cmd);
      }, 5000);
    });
  },

  unmounted: function () {
    clearInterval(this.intervalId);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    games(games) {
      let str = "";
      for (const game in games) {
        str += "  " + game + ":\n";
        for (const region in games[game]) {
          str += "    region " + region + ": ";
          let i = 0;
          for (const level in games[game][region]) {
            if (i++ > 0) str += ", ";
            str += level;
          }
          str += "\n";
        }
      }
      return str;
    },
  },
};
</script>

<style scoped>
.btn-sm {
  margin-right: 2px;
}
.lines {
  white-space: pre;
}
.online {
  color: green;
}
.offline {
  color: red;
}
.online,
.offline {
  margin-top: -10px;
  margin-bottom: 1px;
}
</style>
