<template>
  <div>
    <component :is="currentView" />
    <div class="footer">
      <hr />
      <div class="copyr text-center text-secondary">Teonet © 2024</div>
    </div>
  </div>
</template>

<script>
import PyramidGame from "./components/PyramidGame.vue";
import TeoAdminConnect from "./components/TeoAdminConnect.vue";
import TeoAdmin from "./components/TeoAdmin.vue";
import TeoServers from "./components/TeoServers.vue";
import TeoPlayers from "./components/TeoPlayers.vue";
import TeoHosts from "./components/TeoHosts.vue";
import TeoSettings from "./components/TeoSettings.vue";

const routes = {
  "/": PyramidGame,
  "/admin": TeoAdmin,
  "/adminconnect": TeoAdminConnect,
  "/hosts": TeoHosts,
  "/servers": TeoServers,
  "/players": TeoPlayers,
  "/settings": TeoSettings,
};

export default {
  name: "App",
  components: {
    PyramidGame,
  },
  data() {
    return {
      currentPath: window.location.hash,
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || "/"] || PyramidGame;
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.hash;
    });
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  max-width: 640px;
}
.footer .copyr {
  margin-bottom: 30px;
}
</style>
