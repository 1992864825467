<template>
  <div class="hosts">
    <TeoAdminHeader :showinfo="true" />
    <h2>Hosts</h2>
    <div class="description text-secondary">
      There is list of hosts where game servers running.
    </div>
    <!-- hosts config edit -->
    <edit-config
      name="Edit hosts config"
      jsonName="Hosts config"
      subject="Hosts config."
      :config="'hosts/servers'"
    />
    <!-- hosts list -->
    <div v-for="(host, index) in hosts" :key="host">
      <hr />
      <TeoHost :id="host" :index="index + 1" />
    </div>
  </div>
</template>

<script>
import EditConfig from "./EditConfig.vue";
import TeoAdminHeader from "./TeoAdminHeader.vue";
import TeoHost from "./TeoHost.vue";

const cmdHosts = "hosts.list";
const cmdHostsChanged = "hards-list-changed";
const cmdHostsSubscribe = "hards-list-subscribe";
const cmdHostsUnsubscribe = "hards-list-unsubscribe";

export default {
  name: "TeoHosts",
  components: {
    TeoAdminHeader,
    EditConfig,
    TeoHost,
  },
  data() {
    return {
      hosts: [],
    };
  },
  mounted: function () {
    const that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdHosts:
          that.hosts = JSON.parse(data);
          if (that.hosts == null) {
            that.hosts = [];
          }
          console.log("hosts:", that.hosts);
          break;

        case cmdHostsChanged:
          that.updatePlayers(JSON.parse(data));
          break;
      }
    });

    // Send cmdHosts commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdHosts);
      this.teoweb.sendCmd(cmdHostsSubscribe);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdHostsUnsubscribe);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    updatePlayers(playersChange) {
      const element = { game: playersChange.game, id: playersChange.id };
      const hosts = this.hards;

      // Add element to hosts
      if (playersChange.direction == "+") {
        hosts.push(element);
        return;
      }

      // Remove element from hosts
      let i = 0;
      while (i < hosts.length) {
        if (hosts[i] == element.id) {
          hosts.splice(i, 1);
          break;
        }
        i++;
      }
    },
  },
};
</script>

<style>
</style>
