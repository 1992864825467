<template>
  <div class="admin">
    <TeoAdminHeader :showinfo="true" />
    <h2>Game admin</h2>
    <div class="description text-secondary">
      Game administrator login page.
    </div>
    <teo-login />
  </div>
</template>

<script>

import TeoAdminHeader from "./TeoAdminHeader.vue";
import TeoLogin from "./TeoLogin.vue";

export default {
  name: "TeoAdmin",
  components: {
    TeoAdminHeader,
    TeoLogin,
  },
  data () {
    return {
      doconnect: true,
    };
  },
};
</script>
