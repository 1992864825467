<!-- Server -->
<template>
  <div class="server">
    <h3>{{ index }}. {{ mapData.FriendlyName }}</h3>
    <div class="description">
      ID: {{ mapData.FileId }}<br />
      Game: {{ mapData.GameName }}<br />
      Level: {{ mapData.LevelName }}<br />
      Region: {{ mapData.AwsRegion }}<br />
      <!-- Config: {{mapData.ConfigFile}}<br /> -->
      IP: {{ mapData.ServerIP }}<br />
      Port: {{ mapData.ServerPort }}<br />
      <span v-bind:class="onlineStatus.online ? 'online' : 'offline'"
        >online</span
      >
      / {{ statusName }} / {{ onlineStatus.numUsers }}<br />

      <!-- Buttons -->
      <div class="buttons row">
        <!-- Edit MapId -->
        <edit-config
          class="mt-2 col"
          name="Edit"
          jsonName="Edit servers Map"
          subject=""
          :config="'maps/' + mapData.FileId"
          :outline="true"
          width="w-100"
          @click="swapEditing()"
        />

        <!-- Stop server -->
        <button
          v-if="!editing"
          type="button"
          class="btn btn-outline-secondary btn-sm mt-2 col-2"
          @click="confirm = true"
        >
          Stop
        </button>

        <!-- Stop server confirmation -->
        <div v-if="confirm">
          <EditJson
            :edit="{
              confirm: 'Stop server',
              cmdConfirm: 'server.stop',
              name: mapId,
              params: [],
            }"
            @save="confirm = false"
            @cancel="confirm = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditConfig from "./EditConfig.vue";
import EditJson from "./EditJson.vue";

const cmdGetMap = "map.get/";
const cmdGetOnline = "servers.online/";
const cmdOnlineSubscribe = "servers.online.subscribe/";
const cmdOnlineUnsubscribe = "servers.online.unsubscribe/";
const cmdGameStatus = "servers.game.status/";

export default {
  name: "TeoServer",
  components: { EditConfig, EditJson },
  props: {
    index: Number,
    mapId: String,
  },
  emmits: ["status"],
  data() {
    return {
      mapData: {},
      editing: false,
      confirm: false,
      onlineStatus: { online: false, numUsers: 0, status: 0 },
      statusName: "",
    };
  },

  mounted() {
    let that = this;

    // Create status map if not exists
    if (!this.$gl.statusMap) {
      this.$gl.statusMap = new Map();
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdGetMap + that.mapId:
          that.mapData = JSON.parse(data);
          that.getStatus();
          break;

        case cmdGetOnline + that.mapId:
          that.onlineStatus = JSON.parse(data);
          that.$emit("status", that.onlineStatus);
          that.getStatus();
          break;

        case cmdGameStatus +
          that.mapData.GameName +
          "/" +
          that.onlineStatus.status: {
          let status = JSON.parse(data);
          console.debug("got game status:", status);
          that.statusName = status.Name;

          // Add status to statusMap
          if (!that.$gl.statusMap.has(that.mapData.GameName)) {
            that.$gl.statusMap.set(that.mapData.GameName, new Map());
          }
          if (
            !that.$gl.statusMap
              .get(that.mapData.GameName)
              .has(that.onlineStatus.status)
          ) {
            that.$gl.statusMap
              .get(that.mapData.GameName)
              .set(that.onlineStatus.status, status.Name);
          }

          break;
        }
      }
    });

    // Send requests
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdGetMap + that.mapId);
      that.teoweb.sendCmd(cmdGetOnline + that.mapId);
      that.teoweb.sendCmd(cmdOnlineSubscribe + that.mapId);
      //   that.intervalId = setInterval(function () {
      //     that.teoweb.sendCmd(cmdServers);
      //   }, 10000);
    });
  },

  unmounted: function () {
    // clearInterval(this.intervalId);
    this.teoweb.sendCmd(cmdOnlineUnsubscribe + this.mapId);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    // Get game status. Get status from global status map or request it from
    // server.
    getStatus() {
      // Check game name is defined
      if (!this.mapData.GameName) {
        return;
      }

      // Check game status exists in statusMap
      if (this.$gl.statusMap.has(this.mapData.GameName)) {
        let m = this.$gl.statusMap.get(this.mapData.GameName);
        if (m.has(this.onlineStatus.status)) {
          this.statusName = m.get(this.onlineStatus.status);
          return;
        }
      }

      // Request game status
      this.teoweb.sendCmd(
        cmdGameStatus + this.mapData.GameName + "/" + this.onlineStatus.status
      );
    },

    swapEditing() {
      this.editing = !this.editing;
    },
  },
};
</script>

<style scoped>
.online {
  color: green;
}

.offline {
  color: red;
}

.buttons {
  margin-right: 0;
}
</style>
