<template>
  <div class="players">
    <TeoAdminHeader :showinfo="true" />
    <h2>Game players</h2>
    <div class="description text-secondary">
      There is list of online players.
    </div>

    <!-- servers list -->
    <div v-for="(player, index) in players" :key="player.id">
      <hr />
      <!-- <TeoServer :mapId="server" :index="index+1" /> -->
      <TeoPlayer :index="index" :player="player" />
    </div>
  </div>
</template>

<script>
import TeoAdminHeader from "./TeoAdminHeader.vue";
import TeoPlayer from "./TeoPlayer.vue";

const cmdPlayers = "players.list";
const cmdPlayersChanged = "players-list-changed";
const cmdPlayersSubsribe = "players.list.subscribe";
const cmdPlayersUnsubscribe = "players.list.unsubscribe";

export default {
  name: "TeoPlayers",
  components: {
    TeoAdminHeader,
    TeoPlayer,
  },
  data() {
    return {
      players: [],
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdPlayers:
          that.players = JSON.parse(data);
          if (!that.players) {
            that.players = [];
          }
          console.debug("cmdPlayers answer received:", that.players);
          break;

        case cmdPlayersChanged: {
          let players = JSON.parse(data);
          that.updatePlayers(players);
          console.debug("cmdPlayersChanged answer received:", players);
          break;
        }
      }
    });

    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdPlayers);
      that.teoweb.sendCmd(cmdPlayersSubsribe);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdPlayersUnsubscribe);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    updatePlayers(playersChange) {
      const element = { game: playersChange.game, id: playersChange.id };
      const players = this.players;

      // Add element to players
      if (playersChange.direction == "+") {
        // Find this item in the list of players
        let i = 0;
        while (i < players.length) {
          if (players[i].game == element.game && players[i].id == element.id) {
            break;
          }
          i++;
        }
        if (i < players.length) {
          return;
        }

        players.push(element);
        return;
      }

      // Remove element from players
      let i = 0;
      while (i < players.length) {
        if (players[i].game == element.game && players[i].id == element.id) {
          players.splice(i, 1);
          break;
        }
        i++;
      }
    },
  },
};
</script>
