<template>
  <div class="admin">
    <TeoAdminHeader :doconnect="doconnect" />
    <h2>Game admin</h2>
    <teo-login />
  </div>
</template>

<script>

import TeoAdminHeader from "./TeoAdminHeader.vue";
import TeoLogin from "./TeoLogin.vue";

export default {
  name: "TeoAdmin",
  components: {
    TeoAdminHeader,
    TeoLogin,
  },
  data () {
    return {
      doconnect: true,
    };
  },
};
</script>
