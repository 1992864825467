<!-- Teo games admin menu -->
<template>
  <div class="menu">
    <div class="btn-group">
      <a href="#/admin" class="btn btn-secondary btn-sm"
        >Admin<br /><i class="bi bi-house"></i> </a
      >&nbsp;

      <a href="#/servers" class="btn btn-secondary btn-sm"
        >Servers<br /><i class="bi bi-server"></i>
        <span class="badge text-bg-success">{{ badge.serversNum }}</span></a
      >&nbsp;

      <a href="#/players" class="btn btn-secondary btn-sm"
        >Players<br /><i class="bi bi-people"></i>
        <span class="badge text-bg-success">{{ badge.queueNum }} / {{ badge.playersNum }}</span></a
      >&nbsp;

      <a href="#/hosts" class="btn btn-secondary btn-sm"
        >Hosts<br /><i class="bi bi-hdd-stack"></i>
        <span class="badge text-bg-success">{{ badge.hostsNum }}</span> </a
      >&nbsp;

      <a href="#/settings" class="btn btn-secondary btn-sm"
        >Settings<br /><i class="bi bi-gear"></i>
      </a>
    </div>
    <hr />
  </div>
</template>

<script>
const cmdPlayersNum = "players.num";
const cmdPlayersNumSubscribe = "players.num.subscribe";
const cmdPlayersNumUnsubscribe = "players.num.unsubscribe";

const cmdQueueNum = "players-queue";
const cmdQueueNumSubscribe = "players-queue-subscribe";
const cmdQueueNumUnsubscribe = "players-queue-subscribe";

const cmdServersNum = "servers.num";
const cmdServersNumSubscribe = "servers.num.subscribe";
const cmdServersNumUnsubscribe = "servers.num.unsubscribe";

const cmdHostsNum = "hosts.num";
// const cmdHostsNumSubscribe = "hosts.num.subscribe";
// const cmdHostsNumUnsubscribe = "hosts.num.unsubscribe";

export default {
  name: "TeoMenu",
  components: {},

  data() {
    return {
      badge: {
        serversNum: 0,
        queueNum: 0,
        playersNum: 0,
        hostsNum: 0,
      },
    };
  },

  mounted() {
    let that = this;

    if (this.$gl.badge) {
      this.badge = this.$gl.badge;
    } else {
      this.$gl.badge = this.badge;
    }

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdServersNum:
          that.badge.serversNum = JSON.parse(data);
          that.$gl.badge.serversNum = that.badge.serversNum;
          break;

        case cmdPlayersNum:
          that.badge.playersNum = JSON.parse(data);
          that.$gl.badge.playersNum = that.badge.playersNum;
          break;

        case cmdQueueNum:
          that.badge.queueNum = JSON.parse(data);
          that.$gl.badge.queueNum = that.badge.queueNum;
          break;

        case cmdHostsNum:
          that.badge.hostsNum = JSON.parse(data);
          that.$gl.badge.hostsNum = that.badge.hostsNum;
          break;
      }
    });

    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdServersNumSubscribe);
      that.teoweb.sendCmd(cmdPlayersNumSubscribe);
      // that.teoweb.sendCmd(cmdHostsNumSubscribe);
      that.teoweb.sendCmd(cmdQueueNumSubscribe);
      that.teoweb.sendCmd(cmdHostsNum);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdServersNumUnsubscribe);
    this.teoweb.sendCmd(cmdPlayersNumUnsubscribe);
    // this.teoweb.sendCmd(cmdHostsNumUnsubscribe);
    this.teoweb.sendCmd(cmdQueueNumUnsubscribe);
    this.teoweb.delReader(this.reader);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.badge {
  margin-left: 4px;
}
</style>
