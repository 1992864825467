<template>
  <div class="config-edit body">
    <!-- Edit or Copy -->
    <div v-if="!edit.confirm">
      <div class="form-group">
        <!-- Edit mode headel -->
        <div v-if="!edit.copy">
          <!-- Name: {{ name }} -->
          <div v-for="param in params" :key="param.name">
            {{ param.name }}: {{ param.value }}
          </div>
        </div>

        <!-- Copy mode headel -->
        <div v-else>
          New name:
          <input class="form-control" v-model="name" placeholder="Enter name" />
          <div v-for="(param, i) in params" :key="param.name">
            New {{ param.name }}:
            <input
              class="form-control"
              v-model="params[i].value"
              placeholder="Enter name"
            />
          </div>
        </div>
        <!-- Json: -->
        <textarea class="form-control" name="body" v-model="jsonStr" :rows="countLines()" />

        <div v-show="err" class="error">Error: {{ err }}</div>

        <!--  {{ if .Saved }}
        <div class="saved">Saved</div>
        {{ end }} -->
      </div>
      <div class="form-group">
        <input
          type="button"
          class="btn btn-secondary"
          @click="$emit('cancel')"
          value="Cancel"
        />
        <input
          type="submit"
          class="btn btn-primary float-end"
          :class="{ disabled: !edit.canSave }"
          @click="doSave()"
          value="Save"
        />
      </div>
    </div>

    <!-- Confirm -->
    <div v-else class="form-group">
      <div class="mt-2">Please confirm your {{ edit.confirm }} action:</div>
      <input
        type="button"
        class="btn btn-primary btn-sm mt-2"
        value="Yes"
        @click="doConfirm"
      />
      <input
        type="button"
        class="btn btn-secondary btn-sm mt-2"
        value="No"
        @click="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EditJson",
  props: {
    edit: {},
    // name: String,
    // json: Object,
    // canSave: Boolean,
    // cmdSave: String,
    // params: [],
    // copy: ""
    // confirm: ""
  },
  emits: ["save", "cancel"],
  data() {
    return {
      jsonStr: "", // JSON string used in form
      err: "", // Error message to show JSON.Parse errors
      name: this.edit.name, // Copy of edit.name prop
      params: this.edit.params, // Copy of edit.params.prop
    };
  },
  mounted: function () {
    console.debug("edit-json mounted, name:", this.edit.name);
    if (this.edit.json) {
      this.jsonStr = JSON.stringify(this.edit.json, null, 2);
    }
  },
  unmounted: function () {
    console.debug("edit-json unmounted");
    if (this.reader) {
      this.teoweb.delReader(this.reader);
    }
  },
  methods: {
    /** pare json to input object end emit save event */
    doSave() {
      // Parse json to check errors
      let json = "";
      try {
        json = JSON.parse(this.jsonStr);
      } catch (e) {
        this.err = e; // error in the above string (in this case, yes)!
        return;
      }

      // Stringify and Save json
      let jsonStr = JSON.stringify(json, null, 2);

      // Add save parameters
      let params = "";
      if (this.params) {
        this.params.forEach((p) => (params = params + "/" + p.value));
      }

      // Send save command
      this.teoweb.sendCmd(
        this.edit.cmdSave + params,
        jsonStr
      );

      // Emit event "save"
      this.$emit("save");
    },

    doConfirm() {
      // Add confirm parameters
      let params = "";
      if (this.params) {
        this.params.forEach((p) => (params = params + "/" + p.value));
      }

      // Define confirm command
      let that = this;
      let cmdConfirm = this.edit.cmdConfirm + "/" + this.name + params;
      console.debug("confirm", this.edit.confirm, "command:", cmdConfirm);

      // Add 'reader' which will receive data from WebRTC Data Channel
      this.reader = this.teoweb.addReader(function (gw) {
        console.debug("doConfirm got", gw);
        if (gw.err) {
          return;
        }
        switch (gw.command) {
          case cmdConfirm:
            that.teoweb.delReader(that.reader);
            that.$emit("save");
            break;
        }
      });

      // Send confirm command
      this.teoweb.sendCmd(cmdConfirm);
    },

    /** Calculates  number of lines in json */
    countLines() {
      return this.jsonStr.split("\n").length;
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  max-width: 681px;
}

.body input.save {
  margin-top: 5px;
}

.body textarea {
  width: 100%;
  margin-bottom: 10px;
}

.body .error {
  color: red;
}

.body .saved {
  color: green;
}

.form-group .btn {
  margin-right: 5px;
}
</style>
