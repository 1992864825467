<!-- List filter -->
<template>
  <div class="input-group">
    <input
      class="form-control"
      v-model="filter"
      placeholder="Enter filter text"
    />
    <button
      type="button"
      class="btn bg-transparent"
      @click="clearFilters()"
      style="margin-left: -40px; margin-bottom: 5px; z-index: 100"
    >
      <i class="fa fa-times"></i>x
    </button>

    <button type="button" class="btn btn-primary" @click="changefilterOnline()">
      {{ filterOnlineBtnName }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ListFilter",
  props: {
    name: String,
  },
  data() {
    return {
      filter: "",
      filterOnline: 0,
      filterOnlineBtnName: "All",
    };
  },
  mounted() {
    // Read filter settings
    if (localStorage.getItem(this.name+"/filter/value")) {
      this.filter = localStorage.getItem(this.name+"/filter/value");
    }
    if (localStorage.getItem(this.name+"/filter/filterOnline")) {
      this.filterOnline = parseInt(localStorage.getItem(this.name+"/filter/filterOnline"));
    }
    if (localStorage.getItem(this.name+"/filter/filterOnlineBtnName")) {
      this.filterOnlineBtnName = localStorage.getItem(this.name+"/filter/filterOnlineBtnName");
    }
  },
  unmounted() {
    localStorage.setItem(this.name+"/filter/value", this.filter);
    localStorage.setItem(this.name+"/filter/filterOnline", this.filterOnline);
    localStorage.setItem(this.name+"/filter/filterOnlineBtnName", this.filterOnlineBtnName);
  },
  methods: {
    /** Change state filter online value and buttons name */
    changefilterOnline() {
      this.filterOnline = this.filterOnline < 2 ? ++this.filterOnline : 0;
      switch (this.filterOnline) {
        case 0:
          this.filterOnlineBtnName = "All";
          break;
        case 1:
          this.filterOnlineBtnName = "Online";
          break;
        case 2:
          this.filterOnlineBtnName = "Offline";
          break;
      }
    },

    /** clear all filters information */
    clearFilters() {
      this.filter = "";
      this.filterOnline = 0;
      this.filterOnlineBtnName = "All";
    },
  },
};
</script>
