import { createApp } from 'vue'
import App from './App.vue'

import teoweb from 'teoweb' // to use teonet webrtc connection
import 'bootstrap/dist/css/bootstrap.css' // to use bootstrap CSS
import 'bootstrap/dist/js/bootstrap.js' // to use bootstrap JS
import 'bootstrap-icons/font/bootstrap-icons.css'; // to use bootstrap icons
import './bootstrap_theme_tolge.js' // to use bootstrap color theme tolge
import './telergam_init.js' // to init telegram bot functionality
import uuidv4 from './uuid-v4.js' // to uuidv4 func

const app = createApp(App)

const teo = teoweb();
app.config.globalProperties.teoweb = teo;
window.teoweb = teo;

const uuid = uuidv4;
app.config.globalProperties.uuidv4 = uuid;
window.uuidv4 = uuid;

// application global variables
app.config.globalProperties.$gl = { teoname: "q1w2" }

app.mount('#app')
