<template>
  <p>
    <small class="text-secondary">
      <a
        class="text-secondary"
        href="https://t.me/teonetch"
        target="_blank"
        rel="noopener"
        >Teonet</a
      >
      is designed to create client-server systems and build networks for server
      applications.
    </small>
  </p>
</template>
