<template>
  <div class="settings">
    <TeoAdminHeader :showinfo="true" />

    <h2>Settings</h2>
    <div class="description text-secondary">
      Basic game servers settings.
    </div>
    <EditConfig
      name="Edit MM Conditions"
      jsonName="Matchmaking conditions"
      subject="Matchmaking conditions used when selected game server for player by level."
      config="matchmaking/conditions"
      width="w-75"
    />
    <EditConfig
      name="Edit Scaling Conditions"
      jsonName="Scaling conditions"
      subject="Scaling conditions used to start or stop game servers by level."
      config="scaling/conditions"
      width="w-75"
    />
    <EditConfig
      name="Edit Scaling instances parameters"
      jsonName="Scaling instances parameters"
      subject="Scaling instances parameters used to select game servers by region and level."
      config="scaling/instanceParams"
      width="w-75"
    />

    <h2>Configs</h2>
    <div class="description text-secondary">
      Game configs. Some config files used in games.
    </div>
  </div>
</template>

<script>
import TeoAdminHeader from "./TeoAdminHeader.vue";
import EditConfig from "./EditConfig.vue";

export default {
  name: "TeoSettings",
  components: {
    TeoAdminHeader,
    EditConfig,
  },
  data() {
    return {
    };
  },
};
</script>
