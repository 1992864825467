<template>
  <div class="servers">
    <TeoAdminHeader :showinfo="true" />
    <h2>Game servers</h2>
    <div class="description text-secondary">
      There is list of online game servers.
    </div>

    <!-- Servers status and number of players -->
    <div>Servers: {{ statusSum.servers }} / {{ statusSum.playersTotal }}</div>
    <div>
      Games started: {{ statusSum.started }} / {{ statusSum.playersStarted }}
    </div>
    <div>
      Waiting for players: {{ statusSum.waiting }} /
      {{ statusSum.playersWaiting }}
    </div>
    <br />

    <!-- list filter -->
    <ListFilter name="servers" />

    <!-- servers list -->
    <div v-for="(server, index) in servers" :key="server">
      <!-- Server -->
      &nbsp;
      <TeoServer :mapId="server" :index="index + 1" @status="onStatus" />
    </div>
  </div>
</template>

<script>
import TeoAdminHeader from "./TeoAdminHeader.vue";
import ListFilter from "./ListFilter.vue";
import TeoServer from "./TeoServer.vue";

const cmdServers = "servers.list";
const cmdServersSubsribe = "servers.list.subscribe";
const cmdServersUnsubscribe = "servers.list.unsubscribe";

export default {
  name: "TeoServers",
  components: {
    TeoAdminHeader,
    ListFilter,
    TeoServer,
  },
  data() {
    return {
      servers: [],
      status: new Map(),
      statusSum: {
        servers: 0,
        started: 0,
        waiting: 0,
        playersTotal: 0,
        playersStarted: 0,
        playersWaiting: 0,
      },
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdServers:
          that.servers = JSON.parse(data);
          if (!that.servers) {
            that.servers = [];
          }
          console.debug("cmdServers answer received", that.servers);
          that.statusRemove();
          break;
      }
    });

    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdServers);
      that.teoweb.sendCmd(cmdServersSubsribe);
    });
  },

  unmounted: function () {
    this.teoweb.sendCmd(cmdServersUnsubscribe);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /**
     * A function to handle the status update.
     *
     * @param {status} status - The status object containing mapId and status information.
     */
    onStatus(status) {
      this.status.set(status.mapId, status);
      this.statusCalculate();
    },

    /**
     * Remove elements from this.status map which are not present in this.servers array.
     *
     */
    statusRemove() {
      const statusKeys = new Set(this.servers);
      for (const [key] of this.status) {
        if (!statusKeys.has(key)) {
          this.status.delete(key);
        }
      }
      this.statusCalculate();
    },

    statusCalculate() {
      this.statusSum = {
        servers: 0,
        started: 0,
        waiting: 0,
        playersTotal: 0,
        playersStarted: 0,
        playersWaiting: 0,
      };

      for (const [key, value] of this.status) {
        switch (this.status.get(key).status) {
          case 3:
            this.statusSum.waiting++;
            this.statusSum.playersWaiting += value.numUsers;
            break;
          case 5:
            this.statusSum.started++;
            this.statusSum.playersStarted += value.numUsers;
            break;
        }
        this.statusSum.servers++;
        this.statusSum.playersTotal += value.numUsers;
      }
    },
  },
};
</script>

<style>
.description {
  font-size: small;
  margin-bottom: 10px;
}
</style>
