<template>
  <div class="hello">
    <h1>Welcome to teonet game</h1>
    <TeoDescription />
    <ConnectInfo />

    <div class="frame">
      <img v-if="loading" src="../assets/loading.gif" />
      <iframe
        id="frame"
        src="main.html"
        width="640"
        height="480"
        scrolling="no"
      />
    </div>

    <div class="controls">
      <a @click="keypress('left')" class="btn btn-secondary"><i class="bi bi-arrow-left-circle"></i><br>Left</a>
      <a @click="keypress('up')" class="btn btn-secondary"><i class="bi bi-arrow-up-circle"></i><br>Up</a>
      <a @click="keypress('down')" class="btn btn-secondary"><i class="bi bi-arrow-down-circle"></i><br>Down</a>
      <a @click="keypress('right')" class="btn btn-secondary"><i class="bi bi-arrow-right-circle"></i><br>Right</a>
    </div>

    <div class="d-none">
      <a href="./" class="btn btn-primary float-end">Reload</a>
    </div>
  </div>
</template>

<script>
import ConnectInfo from "./TeoConnect.vue";
import TeoDescription from "./TeoDescription.vue";

export default {
  name: "PyramidGame",
  components: {
    ConnectInfo,
    TeoDescription,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.teoweb.whenConnected(() => (this.loading = false));
  },
  unmounted() {
    this.teoweb.close(true);
  },
  methods: {
    keypress(key) {
      document.getElementById("frame").contentWindow.postMessage(key, "*");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.frame {
  margin: 20px 0;
  max-width: 640px;
  width: 100%;
  height: 360px;
  overflow: hidden;
}

.frame img {
  width: 100%;
  height: 100%;
}

.controls a {
  margin-right: 8px;
  margin-bottom: 10px;
  width: 70px;
}

html,
body,
.container,
.hello {
  height: 100%;
}
</style>
