<template>
  <div>
    <h3>{{ index + 1 }}. {{ capitalise(playerobj.name) }}</h3>
    <div class="description">
      Id: {{ player.id  }}<br />
      MapId: {{ playerobj.map_id }}<br />
      Game: {{ playerobj.game }}<br />
      Level: {{ playerobj.level }}<br />
      Region: {{ playerobj.region }}<br />
    </div>
  </div>
</template>

<script>
const cmdGetPlayer = "player/";

export default {
  name: "TeoPlayer",

  props: {
    index: Number,
    player: Object,
  },

  data() {
    return {
      playerobj: {},
    };
  },

  setup() {},

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdGetPlayer + that.player.game + "/" + that.player.id:
          that.playerobj = JSON.parse(data);
          console.log("get player:", that.playerobj);
          break;
      }
    });

    // Send requests
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(
        cmdGetPlayer + that.player.game + "/" + that.player.id
      );
    });
  },

  unmounted: function () {
    // clearInterval(this.intervalId);
    this.teoweb.delReader(this.reader);
  },

  methods: {
    shortid() {
      const l = 32;
      if (this.player.id && this.player.id.length > l) {
        return this.player.id.substring(0, l) + "...";
      }
      return this.player.id;
    },

    capitalise(name) {
      if (!name || name.length == 0) {
        return name;
      }
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
};
</script>
