/*!
 * Quick uuid generator.
 * Copyright 2024 The Teonet Authors.
 * Use of this source code is governed by a BSD-style
 *license that can be found in the LICENSE file.
 */
'use strict'

/** Generate UUID */
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

export default uuidv4;