<template>
  <div>
    <h1>Teogames admin</h1>
    <TeoDescription />
    <ConnectInfo :doconnect="doconnect" :showinfo="showinfo" :admin="true" />
    <hr />
    <TeoMenu />
  </div>
</template>

<script>
import TeoMenu from "./TeoMenu.vue";
import ConnectInfo from "./TeoConnect.vue";
import TeoDescription from "./TeoDescription.vue";

export default {
  name: "TeoAdminHeader",
  components: {
    TeoMenu,
    ConnectInfo,
    TeoDescription,
  },
  props: {
    doconnect: Boolean,
    showinfo: Boolean,
  },
};
</script>
